<template>
  <MainLayout>
    <template v-slot:navbar-title>
      Редактировать
    </template>
    <CreateFormLayout>
      <template v-slot:title__text>
        {{ currentHeaderTitle }}
      </template>

      <template v-slot:title-flex-right>
        <div></div>
      </template>

      <template>
        <!--    Данные компании    -->
        <div>
          <FormCardTitle class="data__text">Личные данные</FormCardTitle>
          <FormInputBlock>
            <ValidationInputField
              label="Телефон"
              :disabled="
                (currentRole == 70 &&
                  is_phone_confirmed == false &&
                  is_phone_confirmed == true) ||
                  (currentRole == 31 && is_phone_confirmed == true) ||
                  (currentRole == 60 && is_phone_confirmed == true)
              "
              validate-name="телефон"
              rules="required|phone"
              v-model="phone_number"
              mask="+7 (###) ###-##-##"
            />
            <ValidationInputField
              label="Пользователь (необязательно)"
              validate-name="пользователь"
              :rules="'min:1|max:40'"
              v-model="user"
            />
            <ValidationInputField
              label="Комментарий (необязательно)"
              validate-name="комментарий"
              :rules="'min:1|max:40'"
              v-model="comments"
            />
          </FormInputBlock>
        </div>
        <!--    Данные компании    -->
      </template>
      <template v-slot:btns="{ handleSubmit }">
        <a @click="$router.go(-1)" class="btn btn_color_white">
          Отмена
        </a>
        <v-btn
          type="submit"
          @click="handleSubmit(editUser)"
          class="btn btn-green"
        >
          Сохранить
        </v-btn>
      </template>
    </CreateFormLayout>

    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import FormCardTitle from "@/components/createFormBlocks/FormCardTitle";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  name: "EditUserPage",
  components: {
    FormCardTitle,
    ValidationInputField,
    FormInputBlock,
    CreateFormLayout,
    MainLayout
  },
  data() {
    return {
      loading: false
    };
  },
  created() {
    this.$store.dispatch("prepareEditUser", {
      id: this.$route.params.id
    });
  },
  computed: {
    currentHeaderTitle() {
      return this.$route.name === "PersonnelEditId"
        ? "Сотрудник"
        : "Пользователь";
    },
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },
    is_phone_confirmed: {
      get() {
        return this.$store.getters.getUserEditForm.is_phone_confirmed.value;
      }
    },
    phone_number: {
      get() {
        return this.$store.getters.getUserEditForm.phone_number.value;
      },
      set(newValue) {
        this.$store.commit("changeEditValue", {
          formName: "userEditForm",
          fieldName: "phone_number",
          value: newValue
        });
      }
    },
    user: {
      get() {
        return this.$store.getters.getUserEditForm.user.value;
      },
      set(newValue) {
        this.$store.commit("changeEditValue", {
          formName: "userEditForm",
          fieldName: "user",
          value: newValue,
          id: localStorage.getItem("UserFieldId")
        });
      }
    },
    comments: {
      get() {
        return this.$store.getters.getUserEditForm.comments.value;
      },
      set(newValue) {
        this.$store.commit("changeEditValue", {
          formName: "userEditForm",
          fieldName: "comments",
          value: newValue,
          id: localStorage.getItem("CommentsFieldId")
        });
      }
    }
  },
  methods: {
    editUser() {
      this.loading = true;
      this.$store
        .dispatch("editUser", { id: this.$route.params.id })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.edit(successMessageDictionary.user)
          );
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped></style>
